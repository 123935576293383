<template>
  <base-layout>
    <div>
      <iframe
        title="Help Center"
        src="https://otoride-getting-started.vercel.app/"
        class="w-full h-screen outline-none"
        frameborder="0"
      ></iframe>
    </div>
  </base-layout>
</template>

<script>
export default {
  name: 'HelpCenterGettingStarted',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
  },
}
</script>
